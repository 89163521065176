@import "./variables.scss";

@mixin tablet {
  @media (min-width: 768px) and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

/*Buttons*/
@mixin gradient-button(
  $padding-vertical: 0.625rem,
  $padding-horizontal: 1.6875rem,
  $font-size: 1rem
) {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  background: linear-gradient(85.47deg, #ffc943 28.39%, #ff9d43 96.17%);
  box-shadow: 0px 4px 12px rgba(255, 184, 67, 0.68);
  border-radius: 4rem;
  padding: $padding-vertical $padding-horizontal;
  border: none;
  font-family: "Poppins Regular";
  color: $neutral700;
  font-size: $font-size;
  margin-block: auto;

  @media screen and (max-width: 767px) {
    $adjusted-padding-vertical: $padding-vertical * 0.75;
    $adjusted-padding-horizontal: $padding-horizontal * 0.75;
    $adjusted-font-size: $font-size * 0.8;
    padding: $adjusted-padding-vertical $adjusted-padding-horizontal;
    font-size: $adjusted-font-size;
  }
}

@mixin gradient-button-red(
  $padding-vertical: 0.625rem,
  $padding-horizontal: 1.6875rem,
  $font-size: 1rem
) {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  background: linear-gradient(85.47deg, #BE1E2D 28.39%, #BE1E2D 96.17%);
  box-shadow: 0px 4px 12px rgba(139,0,0, 0.68);
  border-radius: 4rem;
  padding: $padding-vertical $padding-horizontal;
  border: none;
  font-family: "Montserrat Regular";
  color: #fafafa;
  font-size: $font-size;
  margin-block: auto;

  @media screen and (max-width: 767px) {
    $adjusted-padding-vertical: $padding-vertical * 0.75;
    $adjusted-padding-horizontal: $padding-horizontal * 0.75;
    $adjusted-font-size: $font-size * 0.8;
    padding: $adjusted-padding-vertical $adjusted-padding-horizontal;
    font-size: $adjusted-font-size;
  }
}

@mixin plain-button(
  $padding-vertical: 0.625rem,
  $padding-horizontal: 1.6875rem,
  $background-color: white
) {
  background-color: $background-color;
  border-radius: 4rem;
  border: 1px solid $neutral700;
  font-family: "Poppins Regular";
  color: $neutral700;
  font-size: 1rem;
  padding: $padding-vertical $padding-horizontal;
}

/*Dashboard Mixin*/

@mixin dashboard-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fffefa;
}

/*Flex Mixins*/

@mixin flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flex-grow {
  display: flex;
  flex-grow: 1;
}

@mixin flex-end {
  display: flex;
  flex-direction: flex-end;
}

@mixin flex-start {
  display: flex;
  flex-direction: flex-start;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*Desktop & Tablet*/
@mixin header {
  font-family: "Poppins Medium";
  font-size: 1.25rem;
  color: $yellow700;
  margin: 0;
}

@mixin subheader {
  font-family: "Poppins Medium";
  font-size: 2.5rem;
  color: $black;
  margin: 0;
}

@mixin body {
  font-family: "Poppins Light";
  // font-size: 1.125rem;
  font-size: 1rem;
}

/*Mobile*/
@mixin mobile-header {
  font-size: 3rem;
  line-height: 3.5rem;
}

@mixin mobile-subheader {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin mobile-body {
  font-size: 0.9375rem;
  line-height: 1.625rem;
}

@mixin normal-text-attributes {
  font-family: "Poppins Regular";
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  font-weight: 500;
}