@import "../../styles/partials/mixins.scss";
@import "../../styles/partials/variables";

.combine-fields {
    @include flex-row;
    gap : 0.25rem;
    width: 100%;

    @media only screen and (max-width: 1020px) {
        @include flex-column;
    }
}

.dropdown-group {
    padding: 0rem 0.5rem;
}

.signature-area-container {
    @include flex-column;
    gap : 0.5rem;
}

.sigCanvas {
    border-radius: 6px;
}

.signature-box {
    @include flex-row;
    gap : 0.5rem;
    
    &__tools {
        @include flex-column;
        justify-content: flex-end;
    }
}

.signature-terms-text {
    @include normal-text-attributes;
    font-size: 12px;
    padding-block: 5px;
    color : $black
}

.employment-dropdown {
    width: 100%;
    padding: 14px 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    border-radius: 0.5rem;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M9.03857 16.5L12.0386 19.5L15.0386 16.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.03857 8.5L12.0386 5.5L15.0386 8.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-position: right 8px top 50%;
    cursor: pointer;
  }


