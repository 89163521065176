@import "../../styles/partials/mixins.scss";
@import "../../styles/partials/variables";


.checkbox-container {
    width: 100%;
    border-radius: 8px;
  }
  
  .checkbox-wrapper {
    @include normal-text-attributes;
    display: flex;
    align-items: center;
    cursor: pointer;
    
    .hidden-checkbox {
      display: none;
    }
  
    .custom-checkbox {
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 1px solid $black;
      border-radius: 4px;
      margin-right: 0.5rem;
      position: relative;
      transition: all 0.2s ease;
  
      &:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 5px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }
  
    .hidden-checkbox:checked + .custom-checkbox {
      background-color: $yellow300;
      border-color: $yellow300;
  
      &:after {
        opacity: 1;
      }
    }
  }
  
  .collapsible-text-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
    color: $black;
  
    .chevron {
      margin-left: 6px;
      transition: transform 0.5s ease;
    }
  
    .chevron.open {
      transform: rotate(180deg);
    }
  }
  
  .collapsible-text-value{
    @include normal-text-attributes;
    font-size: 12px;
    color: $black;
    padding: 5px;
    background: $secondary100;
  }
  